import React, { useRef, useState, useEffect } from "react";
import http from "utils/http";
import SourceBox from "./SourceBox";
import OutputBox from "./OutputBox";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import Loader from "components/loader";
import { useLocation } from "@reach/router";
import { endpoints } from "utils/endpoints";

const AnonymizationTool = () => {
  const [loader, setLoader] = useState(true);
  const [sourceText, setSourceText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [error, setError] = useState(false);
  const [inputLang, setInputLang] = useState("");
  const textareaRef = useRef(null);
  const outputRef = useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const displayShareId = params.get("share-id");

  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        const { user_text: sourceText,source_language_code:sourceCode, } = response.meta;
        setInputLang(sourceCode);
        setSourceText(sourceText);
        setOutputText(response?.result?.text);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoader(false);
      }
    };
    fatchAnalysis();
  }, []);

  if (loader)
    return (
      <div className="grid place-content-center h-[calc(100vh-150px)]">
        <Loader />
      </div>
    );

  return (
    <div>
      <div className="w-full py-7 ">
        <ToolsBreadcrumb />
      </div>

      <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] lg:pb-[60px] pt-12 pb-8 ">
        <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-2.5">
        Free Data Anonymization Tool - Redact Personal Information from Documents
        </h1>
        <h2 className="text-[28px]  md:text-3xl leading-[48px]  text-center font-primary  font-bold ">
        Automatically Remove Personally Identifiable Information from Text, PDFs, and More
        </h2>

        <div className="mt-12 flex lg:flex-row flex-col gap-4">
          <SourceBox
            sourceText={sourceText}
            setSourceText={setSourceText}
            outputText={outputText}
            setOutputText={setOutputText}
            textareaRef={textareaRef}
            outputRef={outputRef}
            error={error}
            setError={setError}
            loader={loader}
            setLoader={setLoader}
            inputLang={inputLang}
          />
          <OutputBox
            outputText={outputText}
            outputRef={outputRef}
            error={error}
            inputLang={inputLang}
          />
        </div>
        <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
          Assisted by AI tools. Inaccuracies may occur.
        </p>
        <p className="mt-8 md:mt-12 text-lg text-center font-opensans">
        Whether you need to redact PDFs for client confidentiality or anonymize large datasets for compliance purposes, the Tomedes Data Anonymization Tool provides real-time, AI-assisted capabilities to safeguard privacy, reduce errors, and ensure sensitive information remains confidential.
        </p>
      </div>
    </div>
  );
};

export default AnonymizationTool;
