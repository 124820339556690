import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import CautionIcon from "assets/caution.svg";
import CopyLink from "assets/copu_link_icon.svg";

const OutputBox = ({
  outputText,
  outputRef,
  error,
  inputLang,
  tooltipContent,
  shareresult,
}) => {
  const [copySuccess, setCopySuccess] = useState("Copy");
  function highlightBracketsText(inputText) {
    const regex = /\[([^\]]+)\]/g;
    const highlightedText = inputText.replace(
      regex,
      '<span class="bg-[#FFBDBD]">$&</span>'
    );

    return highlightedText;
  }

  const copyText = () => {
    if (outputRef.current) {
      const textToCopy = outputRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };

  const downloadText = async () => {
    try {
      const textToDownload = document.querySelector("#output_text").innerText;
      if (!textToDownload) {
        console.error("No text found to download.");
        return;
      }
      const blob = new Blob([textToDownload], { type: "text/plain" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "output";
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };

  return (
    <div
      className={`relative shadow-box  p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans ${
        outputText && "border border-[#5B93FF]"
      }`}
    >
      <div className="flex justify-between items-center">
        <span
          className={`font-bold text-lg ${
            outputText ? "text-[#6E6E6E]" : "text-[#D2D2D2]"
          }`}
        >
          Output Text
        </span>
        <div className="flex gap-3">
          <svg
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copySuccess}
            onClick={copyText}
            className="cursor-pointer border-none outline-none"
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.28628 0C5.86193 0 5.45497 0.168571 5.15491 0.468629C4.85485 0.768687 4.68628 1.17565 4.68628 1.6V3.2H6.28628V1.6H15.0863V10.4H13.4863V12H15.0863C15.5106 12 15.9176 11.8314 16.2177 11.5314C16.5177 11.2313 16.6863 10.8243 16.6863 10.4V1.6C16.6863 1.17565 16.5177 0.768687 16.2177 0.468629C15.9176 0.168571 15.5106 0 15.0863 0H6.28628ZM2.28628 4C1.86193 4 1.45497 4.16857 1.15491 4.46863C0.85485 4.76869 0.686279 5.17565 0.686279 5.6V14.4C0.686279 14.8243 0.85485 15.2313 1.15491 15.5314C1.45497 15.8314 1.86193 16 2.28628 16H11.0863C11.5106 16 11.9176 15.8314 12.2177 15.5314C12.5177 15.2313 12.6863 14.8243 12.6863 14.4V5.6C12.6863 5.17565 12.5177 4.76869 12.2177 4.46863C11.9176 4.16857 11.5106 4 11.0863 4H2.28628ZM2.28628 5.6H11.0863V14.4H2.28628V5.6Z"
              fill="#5B93FF"
            />
          </svg>
          {shareresult && (
            <button
              className="flex items-center gap-2 w-max"
              data-tooltip-id="linkcopy-tooltip"
              data-tooltip-content={tooltipContent}
              onClick={shareresult}
            >
              <img src={CopyLink} alt="copy_link" />
            </button>
          )}
          <svg
            onClick={downloadText}
            className="cursor-pointer outline-none border-none"
            data-tooltip-id="download-tooltip"
            data-tooltip-content="Download"
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
          >
            <path
              d="M2.72549 14.8572C2.18466 14.8572 1.66598 14.6423 1.28355 14.2599C0.901125 13.8775 0.686279 13.3588 0.686279 12.8179V9.90477C0.686279 9.67299 0.778356 9.45069 0.942253 9.2868C1.10615 9.1229 1.32844 9.03082 1.56023 9.03082C1.79201 9.03082 2.01431 9.1229 2.1782 9.2868C2.3421 9.45069 2.43418 9.67299 2.43418 9.90477V12.8179C2.43418 12.9787 2.56469 13.1093 2.72549 13.1093H14.9608C15.0381 13.1093 15.1121 13.0786 15.1668 13.0239C15.2214 12.9693 15.2521 12.8952 15.2521 12.8179V9.90477C15.2521 9.67299 15.3442 9.45069 15.5081 9.2868C15.672 9.1229 15.8943 9.03082 16.1261 9.03082C16.3578 9.03082 16.5801 9.1229 16.744 9.2868C16.9079 9.45069 17 9.67299 17 9.90477V12.8179C17 13.3588 16.7852 13.8775 16.4027 14.2599C16.0203 14.6423 15.5016 14.8572 14.9608 14.8572H2.72549Z"
              fill="#5B93FF"
            />
            <path
              d="M7.96919 7.50315V0.87395C7.96919 0.642164 8.06126 0.419871 8.22516 0.255974C8.38906 0.0920766 8.61135 0 8.84314 0C9.07492 0 9.29721 0.0920766 9.46111 0.255974C9.62501 0.419871 9.71709 0.642164 9.71709 0.87395V7.50315L12.0127 5.20874C12.0938 5.12764 12.19 5.0633 12.296 5.01941C12.402 4.97552 12.5156 4.95292 12.6303 4.95292C12.7449 4.95292 12.8585 4.97552 12.9645 5.01941C13.0705 5.0633 13.1667 5.12764 13.2478 5.20874C13.3289 5.28984 13.3933 5.38613 13.4372 5.49209C13.4811 5.59806 13.5037 5.71163 13.5037 5.82633C13.5037 5.94103 13.4811 6.0546 13.4372 6.16057C13.3933 6.26653 13.3289 6.36282 13.2478 6.44392L9.46073 10.231C9.37967 10.3122 9.2834 10.3766 9.17742 10.4206C9.07145 10.4645 8.95786 10.4871 8.84314 10.4871C8.72842 10.4871 8.61482 10.4645 8.50885 10.4206C8.40288 10.3766 8.30661 10.3122 8.22555 10.231L4.43843 6.44392C4.35733 6.36282 4.29299 6.26653 4.2491 6.16057C4.20521 6.0546 4.18262 5.94103 4.18262 5.82633C4.18262 5.71163 4.20521 5.59806 4.2491 5.49209C4.29299 5.38613 4.35733 5.28984 4.43843 5.20874C4.51953 5.12764 4.61582 5.0633 4.72179 5.01941C4.82775 4.97552 4.94133 4.95292 5.05602 4.95292C5.17072 4.95292 5.28429 4.97552 5.39026 5.01941C5.49623 5.0633 5.59251 5.12764 5.67361 5.20874L7.96919 7.50315Z"
              fill="#5B93FF"
            />
          </svg>
          <Tooltip
            id="copy-tooltip"
            place="top"
            multiline={true}
            className="z-50"
          />
          <Tooltip
            id="linkcopy-tooltip"
            place="top"
            multiline={true}
            className="z-50"
          />
          <Tooltip
            id="download-tooltip"
            place="top"
            multiline={true}
            className="z-50"
          />
        </div>
      </div>
      <hr
        className={`border mt-2 mb-4 ${
          outputText ? "border-[#B0B0B0]" : "border-[#D2D2D2]"
        }`}
      />
      {outputText ? (
        <div className={` flex  items-center justify-center flex-col h-full`}>
          {!outputText ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="106"
                height="97"
                viewBox="0 0 106 97"
                fill="none"
              >
                <path
                  d="M106 11.7065V65.2253C106 71.6884 100.811 76.9318 94.4056 76.9318H42.1714L23.816 97V76.93H11.5944C5.19215 76.93 0 71.6866 0 65.2235V11.7065C0.00177002 5.24159 5.19215 0 11.5944 0H94.4056C100.811 0 106 5.24159 106 11.7065ZM32.1652 38.4641C32.1652 34.3093 28.8298 30.9394 24.7117 30.9394C20.5937 30.9394 17.2583 34.3093 17.2583 38.4641C17.2583 42.6225 20.5937 45.9906 24.7117 45.9906C28.8298 45.9906 32.1652 42.6243 32.1652 38.4641ZM60.4543 38.4641C60.4543 34.3093 57.1154 30.9394 53.0009 30.9394C48.8864 30.9394 45.5474 34.3093 45.5474 38.4641C45.5474 42.6225 48.8864 45.9906 53.0009 45.9906C57.1154 45.9906 60.4543 42.6243 60.4543 38.4641ZM88.7417 38.4641C88.7417 34.3093 85.4028 30.9394 81.2883 30.9394C77.1702 30.9394 73.8348 34.3093 73.8348 38.4641C73.8348 42.6225 77.1702 45.9906 81.2883 45.9906C85.4045 45.9924 88.7417 42.6243 88.7417 38.4641Z"
                  fill="#E8E8E8"
                />
              </svg>
              <p className="text-center mt-8 text-[#858585] ">
                Your results will appear here.
                <br /> Please enter your texts and click "Redact Data" to begin.
              </p>
            </>
          ) : (
            <div className="w-full h-full">
              <div
                dir={inputLang === "he" || inputLang === "ar" ? "rtl" : "ltr"}
                id="output_text"
                ref={outputRef}
                className={` overflow-x-hidden anonymize_tool border-none pr-2 pb-12 ${
                  outputRef?.current?.offsetHeight
                    ? `min-h-[${outputRef?.current?.offsetHeight + 54}px]`
                    : "min-h-[225px]"
                }  max-h-[370px]`}
                dangerouslySetInnerHTML={{
                  __html: highlightBracketsText(
                    outputText
                      .replace(/\n\n/g, "<br><br>")
                      .replace(/\n/g, "<br>")
                  ),
                }}
              />
              {/* <hr className="border border-[#B0B0B0]" /> */}

              <div className="bg-[#F0F5FF] rounded-md p-3 flex items-center gap-5 justify-between mt-3 absolute bottom-[12px] left-0 mx-3 ">
                <img src={CautionIcon} alt="caution-icon" />
                <p className="text-[12px] text-[#6E6E6E] font-opensans">
                  Check the redacted result to ensure all private information is
                  removed and avoid accidental disclosure
                </p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
          {error}
        </span>
      )}
    </div>
  );
};

export default OutputBox;
