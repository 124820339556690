import Layout from "components/layout";
import AnonymizationTool from "components/AnonymizationTool";
import SEO from "components/seo";
import React, { useRef, useState } from "react";
import ToolFaq from "components/QualityTools/ToolFaq";
import { AnonymizationToolFaqData } from "components/FAQ/config";
import Loader from "components/loader";


const QualityTool = () => {

  const title = "Free Data Anonymization & Document Redaction Tool";
  const description =
    "A free Data Anonymization Tool to instantly redact PII and other sensitive data from text, docs, PDFs, and websites. No sign-up needed. Anonymize and remove private info easily.";
 
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        slug="/tools/data-anonymization"
      />
      <AnonymizationTool />
      <ToolFaq QaToolFaqData={AnonymizationToolFaqData} />
    </Layout>
  );
};
export default QualityTool;
